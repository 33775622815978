import { Fragment, lazy, Suspense, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AuthContext from "../Hooks/AuthContext/AuthContext";
import AdminDashboardLayout from "../Layouts/AdminDashboardLayout";
import SuspenseScreen from "./SuspenseScreen";
import { Navigate } from "react-router-dom";
import PagesLayout from "../Layouts/PagesLayout";

const Router = () => {
  const { auth, init } = useContext(AuthContext);
  const routes: any = [
    {
      path: "/signUp",
      element: lazy(() => import("../Pages/SignUp")),
    },

    {
      path: "/admin/*",
      layout: AdminDashboardLayout,
      element: lazy(() => import("../Layouts/AdminDashboardLayout")),
      subRoutes: [
        {
          path: "",

          element: lazy(() => import("../Pages/Dashboard")),
        },
        {
          path: "resumes",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/Resumes")),
        },
        {
          path: "registrations",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/Registrations")),
        },
        {
          path: "tickets",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/Tickets")),
        },
        {
          path: "countryProcess",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/CountryProcess")),
        },
        {
          path: "countryTemplate",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/CountryTemplate")),
        },

        {
          path: "jobTitles",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/JobTitles")),
        },
        {
          path: "processes",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/Processes")),
        },
        {
          path: "process-view",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/ProcessView")),
        },
        {
          path: "skills",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/Skills")),
        },
        {
          path: "resume-view",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/ResumeViewDisplay")),
        },
        {
          path: "subAgent",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/SubAgent")),
        },
        {
          path: "eventLog",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/EventLog")),
        },
        {
          path: "pdf",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/PDFDocument")),
        },
        {
          path: "passports",
          layout: PagesLayout,
          element: lazy(() => import("../Pages/Passports")),
        },
      ],
    },
    {
      path: "/login",
      element: lazy(() => import("../Pages/Login")),
    },
  ];

  const RouteRender = (route: any, i: number) => {
    const Element = route.element ? route.element : Fragment;
    const Layout = route.layout ? route.layout : Fragment;

    return (
      // <Route
      //   key={i}
      //   index={route.path ? false : true}
      //   path={route.path}
      //   element={
      //     <Layout>
      //       <Suspense fallback={<SuspenseScreen />}>
      //         {route.subRoutes ? (
      //           <Routes>
      //             {route.subRoutes.map((subRoute: any, index: any) => {
      //               const SubElement = subRoute.element;
      //               return (
      //                 <Route
      //                   path={subRoute.path}
      //                   element={
      //                     <Suspense fallback={<SuspenseScreen />}>
      //                       {" "}
      //                       <SubElement />
      //                     </Suspense>
      //                   }
      //                   key={"admin" + index}
      //                 />
      //               );
      //             })}
      //           </Routes>
      <Route
        key={i}
        index={route.path ? false : true}
        path={route.path}
        element={
          <Layout>
            <Suspense fallback={<SuspenseScreen />}>
              {route.subRoutes ? (
                <Routes>
                  {route.subRoutes.map((subRoute: any, index: any) => {
                    const SubElement = subRoute.element;
                    const SubLayout = subRoute.layout
                      ? subRoute.layout
                      : Fragment;

                    return (
                      <Route
                        path={subRoute.path}
                        element={
                          <SubLayout>
                            <Suspense fallback={<SuspenseScreen />}>
                              <SubElement />
                            </Suspense>
                          </SubLayout>
                        }
                        key={"admin" + index}
                      />
                    );
                  })}
                </Routes>
              ) : (
                <Element />
              )}
            </Suspense>
          </Layout>
        }
      ></Route>
    );
  };

  useEffect(() => {
    init();
  }, []);
  console.log(auth);
  return (
    <>
      <Routes>{routes.map(RouteRender)}</Routes>
    </>
  );
};

export default Router;
